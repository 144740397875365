

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const transformInstallationsData = (installations, repositories, pullReviews) => {
  return installations.map((installation) => {
    // Count pull requests for each repository within the current installation
    const reposWithPullRequestsInfo = repositories
      .filter((repo) => repo.installation_id === installation.installation_id)
      .map((repo) => {
        const pullRequests = pullReviews.filter(
          (pr) => pr.repository_id === repo.repository_id
        );
        const pullRequestCount = pullRequests.length;
        return {
          ...repo,
          pullRequests,
          pullRequestCount,
          status: repo.config?.is_enabled ? "Enabled" : "Disabled",
        };
      });

    const totalPullRequests = reposWithPullRequestsInfo.reduce(
      (sum, repo) => sum + repo.pullRequestCount,
      0
    );

    const enabledRepositories = reposWithPullRequestsInfo.filter(
      (repo) => repo.config?.is_enabled
    ).length;

    return {
      ...installation,
      gh_account: {
        login: installation.gh_account_login,
        avatar_url: installation.gh_account_avatar_url,
      },
      repositories: reposWithPullRequestsInfo,
      totalPullRequests,
      enabledRepositories,
    };
  });
};


export const diffSizes = (diffTokens) => {
  // diffs below 1000 tokens are considered small
  if (diffTokens < 1000) {
    return "small";
  }
  // diffs between 1000 and 5000 tokens are considered medium
  if (diffTokens >= 1000 && diffTokens < 5000) {
    return "medium";
  }
  // diffs above 5000 tokens are considered large
  if (diffTokens >= 5000) {
    return "large";
  }
};

export const estimateTimeSavedForPRs = (totalPullRequests) => 
totalPullRequests !== null ? (totalPullRequests * 10) / 60 : null