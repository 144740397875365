import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuth } from "src/withAuth";
import useApi from "src/hooks/useApi";
import { Box, Typography } from "@mui/material";

const ExchangeGhAccessCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { linkGhAccount } = useApi();
  const [needsRedirect, setNeedsRedirect] = useState(false);
  const [linkPending, setLinkPending] = useState(false);
  const [accessCode, setAccessCode] = useState<string | null>(null);
  const [codeIsSet, setCodeIsSet] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code && !codeIsSet) {
      setCodeIsSet(true);
      setAccessCode(code);
    }
  }, [location, codeIsSet]);

  useEffect(() => {
    if (accessCode && !linkPending) {
      setLinkPending(true);
      linkGhAccount(accessCode)
        .then(() => {
          console.log("linkGhAccount success");
        })
        .catch((err) => {
          console.log("error during linkGhAccount", err);
        })
        .finally(() => {
          setNeedsRedirect(true);
        });
    }
  }, [accessCode, linkGhAccount, linkPending]);

  useEffect(() => {
    if (needsRedirect) {
      navigate("/");
    }
  }, [needsRedirect, navigate]);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Redirecting</p>
        </div>
      </div>
    </div>
  );
};

export default withAuth(ExchangeGhAccessCode);
