import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { withAuth } from "src/withAuth";
import useApi from "src/hooks/useApi";
import {
  classNames,
  transformInstallationsData,
  diffSizes,
  estimateTimeSavedForPRs,
} from "src/utils";
import { LeftHandNav } from "src/components/LeftHandNav";
import { NavHeader } from "src/components/NavHeader";

import { CircularProgress, LinearProgress } from "@mui/material";
import { Dialog, Menu, Transition } from "@headlessui/react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ToggleWithIcon } from "src/components/Toggles";
import TimeAgo from "timeago-react";
import ActivityFeed from "src/components/ActivityFeed";

const statuses = {
  false: "text-gray-500 bg-gray-100/10",
  true: "text-green-400 bg-green-400/10",
  error: "text-rose-400 bg-rose-400/10",
};

const InstallationDetail = () => {
  const {
    isLoading,
    error,
    hasGhAccessToken,
    installationsInfo,
    updateRepoConfig,
    getInstallations,
  } = useApi();
  const [installations, setInstallations] = useState([]);
  const [repositories, setRepositories] = useState([]);
  const { installationId } = useParams();
  const [installationName, setInstallationName] = useState("");
  const [installationAvatarSrc, setInstallationAvatarSrc] = useState("");
  const [installationTotalPullRequests, setInstallationTotalPullRequests] =
    useState(0);
  const [installationEstTimeSaved, setInstallationEstTimeSaved] = useState(0);
  const [installationPullRequests, setInstallationPullRequests] = useState([]);
  const needsGhAccountLink = !isLoading && !error && !hasGhAccessToken;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [allEnabled, setAllEnabled] = useState(true);
  const [allDisabled, setAllDisabled] = useState(false);

  const handleButtonClick = async (enable: Boolean) => {
    // Handle each repository and enable/disable it accordingly.

    const newConfig = { is_enabled: !allEnabled };
    const promises = [];

    for (const repo of repositories) {
      if (repo) {
        const promise = updateRepoConfig(
          Number(installationId),
          Number(repo.repository_id),
          newConfig
        );
        promises.push(promise);
      }
    }

    await Promise.all(promises);
    await getInstallations();
  };

  useEffect(() => {
    if (installationsInfo) {
      const installation = installationsInfo.installations.find(
        (inst) => inst.installation_id === Number(installationId)
      );
      if (installation) {
        setInstallationName(installation.gh_account_login);
        setInstallationAvatarSrc(installation.gh_account_avatar_url);

        const reposWithPullRequests = installationsInfo.repositories
          .filter(
            (repo) => repo.installation_id === installation.installation_id
          )
          .map((repo) => {
            const pullRequests = installationsInfo.pull_reviews.filter(
              (pr) => pr.repository_id === repo.repository_id
            );
            // sort pull requests by created_at in reverse chronological order
            pullRequests.sort((a, b) => b.created_at - a.created_at);

            // add the repository name to each pull request
            pullRequests.forEach((pr) => {
              pr.gh_name = repo.gh_name;
              pr.gh_full_name = repo.gh_full_name;
            });

            const pullRequestCount = pullRequests.length;
            return {
              ...repo,
              pullRequests,
              pullRequestCount,
              status: repo.config?.is_enabled ? "Enabled" : "Disabled",
            };
          });
        setRepositories([...reposWithPullRequests]);

        // sum up the total pull requests
        const totalPullRequests = reposWithPullRequests.reduce(
          (sum, repo) => sum + repo.pullRequestCount,
          0
        );
        setInstallationTotalPullRequests(totalPullRequests);

        // collect all pull requests and sort by reverse chronological order
        const allPullRequestsForThisInstallation = reposWithPullRequests
          .map((repo) => repo.pullRequests)
          .flat()
          .sort((a, b) => b.created_at - a.created_at);

        setInstallationPullRequests(allPullRequestsForThisInstallation);

        // sum up the total estimated time saved
        setInstallationEstTimeSaved(estimateTimeSavedForPRs(totalPullRequests));
      } else {
        console.log("installation not found");
      }
    }
  }, [installationId, installationsInfo]);

  const setAllDisabledEnabled = (transformedData) => {
    // Iterate through all of the installations in transformed data.
    // If all of them are disabled, set allDisabled to true, otherwise, set it to false
    // If all of them are enabled, set allEnabled to true, otherwise, set it to false
    // The structure of the data is [{installation_id: NUMBER, repositories: [{config: {is_enabled: BOOLEAN}}]}]
    let allDisabled = true;
    let allEnabled = true;
    for (const installation of transformedData) {
      if (installation.installation_id !== Number(installationId)) {
        continue;
      }
      for (const repository of installation.repositories) {
        if (repository.config.is_enabled) {
          allDisabled = false;
        } else {
          allEnabled = false;
        }
      }
    }
    setAllDisabled(allDisabled);
    setAllEnabled(allEnabled);
  };

  useEffect(() => {
    if (installationsInfo && !needsGhAccountLink) {
      const transformedData = transformInstallationsData(
        installationsInfo["installations"],
        installationsInfo["repositories"],
        installationsInfo["pull_reviews"]
      );
      setInstallations(transformedData);
      setAllDisabledEnabled(transformedData);
    }
  }, [needsGhAccountLink, installationsInfo]);

  return (
    <>
      <LeftHandNav
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        installations={installations}
      />

      <div className="lg:pl-72">
        <NavHeader setSidebarOpen={setSidebarOpen} />
        {isLoading && <LinearProgress />}

        <main className="py-10">
          <div
            id="installation-detail"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            {/* Heading */}
            <div className="flex flex-col items-start justify-start gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
              <div>
                <img
                  className="h-16 w-16 rounded-full"
                  src={installationAvatarSrc}
                  alt=""
                />
              </div>
              <div>
                <div className="flex items-center gap-x-3">
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                  <h1 className="flex gap-x-3 text-2xl leading-7">
                    <span className="font-semibold text-black">
                      {installationName}
                    </span>
                    {/* <span className="text-gray-600">/</span>
                      <span className="font-semibold text-black">mobile-api</span> */}
                  </h1>
                </div>
                <p className="ml-7 mt-2 text-lg leading-6 text-black">
                  Github Organization
                </p>{" "}
                {/* or account */}
              </div>
              <div className="order-first ml-auto flex flex-none items-center px-2 py-1 text-xs font-medium  sm:order-none">
                <span className="mr-4 text-gray-400">
                  {allEnabled ? "Disable" : "Enable"} All
                </span>
                <ToggleWithIcon
                  enabled={allEnabled}
                  setEnabled={handleButtonClick}
                ></ToggleWithIcon>
              </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
              <div
                className={classNames(
                  "border-t border-black/5 px-4 py-6 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  Pull Requests
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-black">
                    {installationTotalPullRequests}
                  </span>
                </p>
              </div>

              <div
                className={classNames(
                  "border-t border-black/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  Estimated PR Time Saved
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-black">
                    {Number.isFinite(installationEstTimeSaved) ? (
                      `${installationEstTimeSaved.toFixed(2)}`
                    ) : (
                      <CircularProgress />
                    )}
                  </span>
                  <span className="text-sm text-gray-400">Hours</span>
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto flex max-w-7xl flex-wrap px-4 sm:px-6 lg:px-8">
            <div className="flex-1">
              <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                <h1 className="text-base font-semibold leading-7 text-black">
                  Repositories
                </h1>

                {/* Sort dropdown */}
                {/* <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-black">
                      Sort by
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              Name
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              Date updated
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              Environment
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu> */}
              </header>

              <ul role="list" className="divide-y divide-white/5">
                {repositories.map((repo) => (
                  <a href={`${installationId}/${repo.repository_id}`}>
                    <li
                      key={repo.repository_id}
                      className="relative flex items-center space-x-4 px-4 py-4 hover:bg-slate-100 sm:px-6 lg:px-8"
                    >
                      <div className="min-w-0 flex-auto">
                        <div className="flex items-center gap-x-3">
                          <h2 className="min-w-0 text-sm font-semibold leading-6 text-black">
                            <a href={repo.href} className="flex gap-x-2">
                              <span className="truncate">
                                {repo.gh_owner_login}
                              </span>
                              <span className="text-gray-400">/</span>
                              <span className="whitespace-nowrap">
                                {repo.gh_name}
                              </span>
                              <span className="absolute inset-0" />
                            </a>
                          </h2>
                        </div>
                        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                          <div
                            className={classNames(
                              statuses[repo.config.is_enabled],
                              "flex-none rounded-full p-1"
                            )}
                          >
                            <div className="h-2 w-2 rounded-full bg-current" />
                          </div>
                          <p className="truncate">
                            PR reviews{" "}
                            {repo.config.is_enabled ? "active" : "disabled"}
                          </p>
                          <svg
                            viewBox="0 0 2 2"
                            className="h-0.5 w-0.5 flex-none fill-gray-300"
                          >
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          {repo.pullRequests.length > 0 && (
                            <p className="whitespace-nowrap">
                              Last activity{" "}
                              <TimeAgo
                                datetime={repo.pullRequests[0].created_at}
                              ></TimeAgo>
                            </p>
                          )}
                        </div>
                      </div>

                      <ChevronRightIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </li>
                  </a>
                ))}
              </ul>
            </div>

            <ActivityFeed prItems={installationPullRequests}></ActivityFeed>
          </div>
        </main>
      </div>
    </>
  );
};

export default withAuth(InstallationDetail);
