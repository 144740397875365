import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, LinearProgress } from "@mui/material";
import { withAuth } from "src/withAuth";
import useApi from "src/hooks/useApi";
import {
  classNames,
  transformInstallationsData,
  diffSizes,
  estimateTimeSavedForPRs,
} from "src/utils";
import { LeftHandNav } from "src/components/LeftHandNav";
import { NavHeader } from "src/components/NavHeader";
import { ToggleWithIcon } from "src/components/Toggles";
import TimeAgo from "timeago-react";
import ActivityFeed from "src/components/ActivityFeed";

import defaultRules from "src/defaultRules.json";

const statuses = {
  false: "text-gray-500 bg-gray-100/10",
  true: "text-green-400 bg-green-400/10",
  error: "text-rose-400 bg-rose-400/10",
};

const RepoDetail = () => {
  const {
    isLoading,
    error,
    hasGhAccessToken,
    installationsInfo,
    updateRepoConfig,
    getInstallations,
    updateRepoRules,
  } = useApi();
  const [installations, setInstallations] = useState([]);
  const [pullReviews, setPullReviews] = useState([]);
  const { installationId, repoId } = useParams();
  const [installationName, setInstallationName] = useState("");
  const [repo, setRepo] = useState(null);
  const [repoName, setRepoName] = useState("");
  const [repoEstTimeSaved, setRepoEstTimeSaved] = useState(0);
  const [rules, setRules] = useState([]);
  const [activeRules, setActiveRules] = useState([]);
  const needsGhAccountLink = !isLoading && !error && !hasGhAccessToken;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleRepoEnabled = async () => {
    const newConfig = {
      is_enabled: !isRepoEnabled,
    };
    await updateRepoConfig(
      Number(installationId),
      repo.repository_id,
      newConfig
    );
    await getInstallations();
  };

  useEffect(() => {
    if (repo && repo.config) {
      if (defaultRules && repo.rules && repo.rules.length === 0) {
        setActiveRules(
          defaultRules.predefined_rules.map((rule) => ({
            predefined_rule_id: rule.predefined_rule_id,
            arguments: [],
          }))
        );
      } else {
        setActiveRules(
          repo.rules.map((rule) => ({
            predefined_rule_id: rule.predefined_rule_id,
            arguments: [],
          }))
        );
      }
      setIsRepoEnabled(repo.config.is_enabled);
    }
  }, [repo]);

  useEffect(() => {
    if (installationsInfo && installationId) {
      const installation = installationsInfo.installations.find(
        (inst) => inst.installation_id === Number(installationId)
      );
      const repo = installationsInfo.repositories.find(
        (repo) => repo.repository_id === Number(repoId)
      );
      setRepo(repo);
      console.log("setting rules: ", defaultRules.predefined_rules);
      setRules(defaultRules.predefined_rules);
      setRepoName(repo.gh_name);
      setInstallationName(installation.gh_account_login);
      if (installation) {
        let pullRequests = installationsInfo.pull_reviews.filter(
          (pr) => pr.repository_id === Number(repoId)
        );
        // sort pull requests by created_at in reverse chronological order
        pullRequests.sort((a, b) => b.created_at - a.created_at);
        // Add the installationName and repoName to each pull request
        pullRequests = pullRequests.map((pr) => ({
          ...pr,
          installationName,
          repoName,
          gh_full_name: `${installationName}/${repoName}`,
        }));
        console.log(pullRequests);
        setPullReviews(pullRequests);
        setRepoEstTimeSaved(estimateTimeSavedForPRs(pullRequests.length));
      }
    }
  }, [installationsInfo, repoId, installationId, installationName, repoName]);

  useEffect(() => {
    console.log("useEffect for hasGhAccessToken, error");
    if (error) {
      console.log("useApi error", error);
    }
  }, [hasGhAccessToken, error]);

  useEffect(() => {
    console.log("install info and needs gh account link useEffect");
    if (installationsInfo && !needsGhAccountLink) {
      const transformedData = transformInstallationsData(
        installationsInfo["installations"],
        installationsInfo["repositories"],
        installationsInfo["pull_reviews"]
      );
      setInstallations(transformedData);
    }
  }, [needsGhAccountLink, installationsInfo]);

  useEffect(() => {
    if (installationId && repoId) {
      updateRepoRules(Number(installationId), Number(repoId), activeRules);
    }
  }, [activeRules]);

  // State for enabling/disabling the repository configuration
  const [isRepoEnabled, setIsRepoEnabled] = useState(true);

  return (
    <>
      <LeftHandNav
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        installations={installations}
      />

      <div className="lg:pl-72">
        <NavHeader setSidebarOpen={setSidebarOpen} />
        {isLoading && <LinearProgress />}

        <main className="py-10">
          <div
            id="repository-detail"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <div className="flex flex-col items-start justify-start gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
              <div>
                <div className="flex items-center gap-x-3">
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                  <h1 className="flex gap-x-3 text-2xl leading-7">
                    <span className="font-semibold text-black">
                      {installationName}
                    </span>
                    <span className="text-gray-400">/</span>
                    <span className="font-semibold text-black">{repoName}</span>
                    {/* <span className="text-gray-600">/</span>
                      <span className="font-semibold text-black">mobile-api</span> */}
                  </h1>
                </div>
                <p className="ml-7 mt-2 text-lg leading-6 text-black">
                  Github Repository
                </p>
              </div>
              <div className="order-first ml-auto flex flex-none items-center px-2 py-1 text-xs font-medium  sm:order-none">
                <span className="mr-4 text-gray-400">Enabled</span>
                <ToggleWithIcon
                  enabled={isRepoEnabled}
                  setEnabled={toggleRepoEnabled}
                ></ToggleWithIcon>
              </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
              <div
                className={classNames(
                  "border-t border-black/5 px-4 py-6 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  Pull Requests
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-black">
                    {pullReviews.length}
                  </span>
                </p>
              </div>

              <div
                className={classNames(
                  "border-t border-black/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  Estimated PR Time Saved
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-black">
                    {Number.isFinite(repoEstTimeSaved) ? (
                      `${repoEstTimeSaved.toFixed(2)}`
                    ) : (
                      <CircularProgress />
                    )}
                  </span>
                  <span className="text-sm text-gray-400">Hours</span>
                </p>
              </div>
            </div>

            <div className="mx-auto flex max-w-7xl flex-wrap px-4 sm:px-6 lg:px-8">
              <div className="flex-1">
                <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                  <h1 className="text-base font-semibold leading-7 text-black">
                    PR Review Rules
                  </h1>
                </header>

                {/* <ul role="list" className="divide-y divide-white/5"> */}
                <fieldset>
                  <legend className="sr-only">Notifications</legend>

                  {rules.map((rule) => (
                    <div
                      className="relative flex items-center px-4 py-1 sm:px-6 lg:px-8"
                      key={rule.predefined_rule_id}
                    >
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          // Change: Check if rule is active based on predefined_rule_id
                          checked={activeRules.some(
                            (activeRule) =>
                              activeRule.predefined_rule_id ===
                              rule.predefined_rule_id
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setActiveRules([
                                ...activeRules,
                                {
                                  predefined_rule_id: rule.predefined_rule_id,
                                  arguments: [],
                                },
                              ]);
                            } else {
                              setActiveRules(
                                activeRules.filter(
                                  (activeRule) =>
                                    activeRule.predefined_rule_id !==
                                    rule.predefined_rule_id
                                )
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="comments" className="text-gray-00">
                          {rule.template}
                        </label>
                      </div>
                      <hr className="absolute bottom-0 left-0 right-0 border-gray-200" />
                    </div>
                  ))}
                </fieldset>
              </div>

              {/* Activity feed */}
              <ActivityFeed prItems={pullReviews}></ActivityFeed>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default withAuth(RepoDetail);
