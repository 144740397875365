import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { CircularProgress } from "@mui/material";

export function withAuth(WrappedComponent: React.ComponentType) {
  return function Component(props: any) {
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
          navigate("/login");
        } else {
          setLoading(false);
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

    // While the user state is being determined, show a loading spinner or similar and center it.
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
}

export function withoutAuth(WrappedComponent: React.ComponentType) {
  return function Component(props: any) {
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          navigate("/"); // Redirect to home page if user is authenticated
        } else {
          setLoading(false);
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

    // While the user state is being determined, show a loading spinner or similar and center it.
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
}
