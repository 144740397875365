import * as React from "react";
import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react'
import {
  XMarkIcon,
  ChartBarSquareIcon
} from '@heroicons/react/24/outline'
import { CodeBracketIcon, ChatBubbleOvalLeftIcon, PlayIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { GitHubIcon } from "src/components/SocialIcons";

import { FeedbackModal } from "src/components/FeedbackModal";
import { classNames } from "src/utils";

export interface LeftHandNavProps {
  setSidebarOpen: (boolean) => void;
  sidebarOpen: boolean;
  installations: Array<any>; // todo this type
}

type NavItemProps = {
  to: string
  children: React.ReactNode;
}
const NavItem = ({to, children}: NavItemProps) => {
  /* 
  A simple component to centralize the styling logic of the navigation items.
  Defined in this file as it is specific to the LeftHandNav component below.

  Example usage:

  ```
    <NavItem to="/place">
        <ChartBarSquareIcon
            className="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
            aria-hidden="true"
          />Dashbard
    </NavItem>
  ```
  */

  return (
      <NavLink
      to={to}
      end={true} // needed to prevent active class from being applied to parent nav item
      className={({ isActive, isPending }) =>
        classNames(
          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-gray-50",
          isPending ? "" : isActive ? "text-blue-600 bg-gray-100" : ""
        )
      }
      >
        {children}
      </NavLink>
  )
}


const Navigation = ({installations}: {installations: Array<any>}) => {

          return(
            <ul role="list" className="-mx-2 space-y-1">                    
              <li>                          
                  <NavItem to="/">
                    <SparklesIcon
                        className="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />Getting Started
                  </NavItem>
              </li>
              <div className="text-xs font-semibold leading-6 text-gray-400">PROJECTS</div>
              {installations.map((i) => (
                <li key={i.installation_id}>
                      <NavItem to={`/${i.installation_id}`}>
                        <GitHubIcon size={5} className="text-gray-500"/>{i.gh_account_login}
                      </NavItem>

                      <ul role="list" className="ml-1 px-2 space-y-1">
                        {i.repositories.map((repo) => (
                          <li key={repo.repository_id}>
                          <NavItem to={`/${i.installation_id}/${repo.repository_id}`}>
                            <CodeBracketIcon
                                className={classNames(
                                  'text-gray-400 group-hover:text-blue-600',
                                  'h-5 w-5 pt-1 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              <span className="truncate">{repo.gh_name}</span>
                          </NavItem>
                        </li>
                        ))}
                      </ul>
                </li>
              ))}
            </ul>
          )
}
  

const FeedbackAndSupportNavItem = () => {
  // test
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

  return (
    <li className="mt-auto">
      <a
        className="group -mx-2 flex gap-x-3 rounded-md p-2 cursor-pointer text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-600"
        onClick={() => setFeedbackModalOpen(true)}
      >
        <ChatBubbleOvalLeftIcon
          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
          aria-hidden="true"
        />
        Feedback & Support
      </a>
      <FeedbackModal open={feedbackModalOpen} setOpen={setFeedbackModalOpen}></FeedbackModal>
    </li>
  )
}

export function LeftHandNav({setSidebarOpen, sidebarOpen, installations}: LeftHandNavProps) {
  /*
    Left hand navigation component for the dashboard.
    Note there are two versions in the jsx below, one for desktop and one for mobile. So when
    make sure to update both versions when making changes.
  */
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

  return (
    <>
        {/* -------------------------------- Desktop version of the sidebar -------------------------------- */}
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="/logo-temp-gradient-isolated.png"
                alt="AI Maintainer"
              />
              <span className="font-semibold text-lg ml-3">AI Maintainer</span>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <Navigation installations={installations}></Navigation>
                </li>
                <li className="mt-auto">
                  <a
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 cursor-pointer text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-600"
                    onClick={() => setFeedbackModalOpen(true)}
                  >
                    <ChatBubbleOvalLeftIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                      aria-hidden="true"
                    />
                    Feedback & Support
                  </a>
                  <FeedbackModal open={feedbackModalOpen} setOpen={setFeedbackModalOpen}></FeedbackModal>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* -------------------------------- sm/mobile version of the sidebar -------------------------------- */}
        {/* On mobile, the sidebar can show and hide */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={()=>{setSidebarOpen(false)}}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => {setSidebarOpen(false)}}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/logo-temp-gradient-isolated.png"
                        alt="AI Maintainer"
                      />
                      <span className="font-semibold text-lg ml-3">AI Maintainer</span>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <Navigation installations={installations}></Navigation>
                        </li>

                        <li className="mt-auto">
                          <a
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-600"
                          >
                            <ChatBubbleOvalLeftIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                              aria-hidden="true"
                            />
                            Feedback & Support
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
    </>
  )
}

