import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "src/pages/index";
import ExchangeGHAccessCode from "src/pages/exchange-gh-access-code";
import Installation from "src/pages/[installationId]/index";
import RepoId from "src/pages/[installationId]/[repoId]";
import LinkGHAccount from "src/pages/link-gh-account";
import Login from "src/pages/login";
import Register from "src/pages/register";
import { UserAuthContextProvider } from "src/context/userAuthContext";

import "./App.css";

function App() {
  React.useEffect(() => {
    document.title = "AI Maintainer - Your new code reviewing assistant";
  }, []);
  return (
    <div className="wrapper">
      <UserAuthContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/link-gh-account" element={<LinkGHAccount />} />
            <Route
              path="/exchange-gh-access-code"
              element={<ExchangeGHAccessCode />}
            />
            <Route path="/:installationId" element={<Installation />} />
            <Route path="/:installationId/:repoId" element={<RepoId />} />
          </Routes>
        </Router>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
