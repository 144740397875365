import { useNavigate } from "react-router-dom";

import { ChangeEvent, FormEvent, MouseEvent } from "react";

import GoogleButton from "react-google-button";
import GithubButton from "react-github-login-button";
import {
  TextField,
  Button,
  Typography,
  Alert,
  Grid,
  Divider,
  CssBaseline,
  Link,
} from "@mui/material";

import { useUserAuth } from "src/context/userAuthContext";
import { withoutAuth } from "src/withAuth";
import { Box } from "@mui/system";

function Login() {
  const {
    email,
    password,
    setEmail,
    setPassword,
    emailLogIn,
    googleLogIn,
    githubLogIn,
    error,
    localSendPasswordResetEmail,
    successMessage,
  } = useUserAuth();
  const navigate = useNavigate();

  const handleEmailPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const submitEmailPasswordLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await emailLogIn(email, password);

    if (!error) {
      navigate("/");
    }
  };

  const handleGoogleLogIn = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    await googleLogIn();
    navigate("/");
  };

  const handleGithubLogIn = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    await githubLogIn();
    navigate("/");
  };

  const handleForgotPassword = async () => {
    await localSendPasswordResetEmail(email);
  };

  return (
    <>
      {/* <head>
        <title>Sign In</title>
      </head> */}
      <Box
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            flex: { xs: 1, sm: 1 },
            p: 8,
            display: "flex",
            alignItems: "center",
            borderRight: { sm: "1px solid #ccc" },
          }}
        >
          <Box sx={{ maxWidth: "400px", margin: "0 auto" }}>
            <Typography variant="h5" component="h2">
              Sign In
            </Typography>

            <Box sx={{ width: "100%", mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GoogleButton
                    type="light"
                    label="Sign in with Google"
                    onClick={handleGoogleLogIn}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GithubButton
                    type="light"
                    label="Sign in with Github"
                    onClick={handleGithubLogIn}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, width: "100%" }}>
              <Divider variant="middle" sx={{ my: 2 }}>
                or
              </Divider>
            </Box>
            <form
              action="#"
              onSubmit={submitEmailPasswordLogin}
              style={{ width: "100%" }}
            >
              <TextField
                value={email}
                onChange={handleEmailPasswordChange}
                label="Email address"
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                fullWidth
                required
              />
              <TextField
                value={password}
                onChange={handleEmailPasswordChange}
                label="Password"
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                fullWidth
                sx={{ mt: 2 }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3, py: 2 }}
              >
                Sign In
              </Button>
            </form>
            {error && (
              <Alert severity="error" sx={{ width: "100%", mt: 3 }}>
                {error.message}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ width: "100%", mt: 3 }}>
                {successMessage}
              </Alert>
            )}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <Link
                component="button"
                variant="body2"
                onClick={handleForgotPassword}
                sx={{ display: "block", mt: 2 }}
              >
                Forgot Password?
              </Link>
              or, <Link href="/register">Sign Up Free</Link>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flex: { xs: 0, sm: 2 },
            background: {
              sm: "linear-gradient(45deg, #3f51b5 30%, #7986cb 90%)",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            p: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            variant="h4"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Welcome Back!
          </Typography>{" "}
        </Box>
      </Box>
    </>
  );
}

export default withoutAuth(Login);
