import TimeAgo from "timeago-react";
import { classNames, diffSizes } from "src/utils";

// Todo: pagination
// Todo? - try adding timeline line+dots https://flowbite.com/docs/components/timeline/

export function sizePill(diffTokens: number) {
  /*
    Renders a little colored pill that indicates the size of the diff
  */
  const sizeString = diffSizes(diffTokens);
  const baseClasses =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";
  return (
    <>
      {(() => {
        switch (sizeString) {
          case "small":
            return (
              <span
                className={classNames(
                  baseClasses,
                  "bg-green-100 text-green-800"
                )}
              >
                {sizeString}
              </span>
            );
          case "medium":
            return (
              <span
                className={classNames(
                  baseClasses,
                  "bg-yellow-100 text-yellow-600"
                )}
              >
                {sizeString}
              </span>
            );
          case "large":
            return (
              <span
                className={classNames(
                  baseClasses,
                  "bg-orange-100 text-orange-500"
                )}
              >
                {sizeString}
              </span>
            );
        }
      })()}
    </>
  );
}

export interface ActivityFeedProps {
  prItems: any[];
  title?: string;
}
export default function ActivityFeed({ prItems, title }: ActivityFeedProps) {
  /*
    Todo: pagination
  */
  const _title = title ?? "Activity";

  return (
    <aside className="flex-1  lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5">
      <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
        <h2 className="text-base font-semibold leading-7 text-black">
          {_title}
        </h2>
        {/* <a href="#" className="text-sm font-semibold leading-6 text-indigo-400">
        View all
      </a> */}
      </header>
      <ul role="list" className="divide-y divide-white/5">
        {prItems.map((pr) => (
          <li key={pr.pull_review_id} className="px-4 py-4 sm:px-6 lg:px-8">
            <div className="flex items-center gap-x-3">
              <a
                href={`https://github.com/${pr.gh_full_name}/pull/${pr.pull_number}`}
                target="_blank"
                className=" text-blue-600"
              >
                <h3 className="flex-auto truncate text-sm leading-6">
                  {pr.gh_name} PR #{pr.pull_number}
                </h3>
              </a>
              <TimeAgo
                datetime={pr.created_at}
                className="ml-auto flex-none text-xs text-gray-600"
              >
                {pr.created_at}
              </TimeAgo>
            </div>
            <p className="mt-3 truncate text-sm text-gray-500">
              {sizePill(pr.diff_tokens)} sized diff
            </p>
          </li>
        ))}
      </ul>
    </aside>
  );
}
