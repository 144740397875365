import * as React from "react";
import { Fragment, useState } from "react";
import { withAuth } from "src/withAuth";
import useApi from "src/hooks/useApi";
import { LeftHandNav } from "src/components/LeftHandNav";
import { NavHeader } from "src/components/NavHeader";
import { classNames, transformInstallationsData } from "src/utils";

import { useNavigate, useLocation } from "react-router-dom";

const StepCheckBox = ({ isComplete }) => {
  const inner = (isComplete) => {
    if (isComplete) {
      return <div className="h-6 w-6 border-4 border-sky-700"></div>;
    } else {
      return <p className="text-3xl">✅</p>;
    }
  };

  return <div className="mt-2 w-6 flex-shrink-0">{inner(!isComplete)}</div>;
};

function Console() {
  const [installations, setInstallations] = React.useState([]);
  const {
    isRefreshing,
    isLoading,
    error,
    hasGhAccessToken,
    installationsInfo,
    updateRepoConfig,
    refreshInstallations,
    getInstallations,
  } = useApi();

  const navigate = useNavigate();
  const location = useLocation();
  const [isMounted, setIsMounted] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const hasGhAccountLink = !isLoading && !error && hasGhAccessToken;
  const hasGhAppInstalled =
    installationsInfo && installationsInfo.installations.length > 0;
  const hasSomeRepositories = installations.some(
    (installation) => installation.repositories.length > 0
  );

  React.useEffect(() => {
    if (installationsInfo && hasGhAccountLink) {
      const transformedData = transformInstallationsData(
        installationsInfo["installations"],
        installationsInfo["repositories"],
        installationsInfo["pull_reviews"]
      );
      setInstallations(transformedData);
    }

    if (!isMounted) {
      setIsMounted(true);
    }
  }, [
    hasGhAccountLink,
    installationsInfo,
    navigate,
    location.pathname,
    isMounted,
  ]);

  React.useEffect(() => {
    console.log("useApi error", error);
    console.log("hasGhAccessToken", hasGhAccessToken);
    console.log("hasGhAppInstalled", hasGhAppInstalled);
    console.log("hasGhAccountLink", hasGhAccountLink);
    console.log("hasSomeRepositories", hasSomeRepositories);
  }, [
    error,
    hasGhAccessToken,
    hasGhAppInstalled,
    hasSomeRepositories,
    hasGhAccountLink,
  ]);

  React.useEffect(() => {
    if (isMounted && !isReady && !isLoading && !isRefreshing) {
      setIsReady(true);
    }
  }, [isLoading, isMounted, isReady, isRefreshing]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <LeftHandNav
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        installations={installations}
      />
      <div className="lg:pl-72">
        <NavHeader setSidebarOpen={setSidebarOpen} />

        {isReady && !isLoading && (
          <main className="px-12 py-10">
            {/* <div className="flex-column mx-auto max-w-7xl justify-between px-4 sm:px-6 lg:px-8"> */}
            <div className="flex gap-x-4">
              <StepCheckBox isComplete={hasGhAccountLink} />
              <div className="ml-6">
                <h1 className="text-2xl font-bold text-gray-900">Step 1</h1>
                <h2 className="text-xl text-gray-900">
                  Link your GitHub Account
                </h2>

                {/* Always show step 1 */}
                {/* { !needsGhAccountLink && (    // or, use this to show ony if GitHub not yet linked*/}
                {true && (
                  <div className="mt-8">
                    <a href="/link-gh-account">
                      <button
                        type="button"
                        className="rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Link your GitHub Account
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* Always show step 2 */}
            {/* { (!needsGhAccountLink && !hasGhAppInstalled) && (    // or, use this to show only if step 1 is complete */}
            {true && (
              <div className="mt-16 flex gap-x-4">
                <StepCheckBox
                  isComplete={hasGhAppInstalled || hasSomeRepositories}
                />
                <div className="ml-6">
                  <h1 className="text-2xl font-bold text-gray-900">Step 2</h1>
                  <h2 className="text-xl text-gray-900">
                    Install the AI Maintainer GitHub App
                  </h2>

                  {true && (
                    <div className="mt-8">
                      <a href="https://github.com/apps/ai-maintainer">
                        <button
                          type="button"
                          className="rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                          Install the GitHub App
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}

            {hasGhAccountLink && hasGhAppInstalled && !hasSomeRepositories && (
              <div className="mt-16 flex gap-x-4">
                <StepCheckBox isComplete={hasSomeRepositories} />
                <div className="ml-6">
                  <h1 className="text-2xl font-bold text-gray-900">Step 3</h1>
                  <h2 className="text-xl text-gray-900">
                    Make sure your organization has repositories
                  </h2>
                </div>
              </div>
            )}
            {/* </div> */}
          </main>
        )}
      </div>
    </>
  );
}
export default withAuth(Console);
