import * as React from "react";
import { withAuth } from "src/withAuth";
import { Box, Typography } from "@mui/material";

const LinkGhAccount = () => {
  const clientId = "Iv1.455ea0e8c466fa9b";
  const defaultRedirectURI =
    "https://console.ai-maintainer.com/exchange-gh-access-code";
  const redirectURI =
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_CONSOLE_HOST}/exchange-gh-access-code`
      : defaultRedirectURI;
  const ghURI = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}`;

  React.useEffect(() => {
    console.log("redirecting for GitHub Web Application Flow");
    console.log("destination", ghURI);
    window.location.assign(ghURI);
  }, [ghURI]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 8,
      }}
    >
      <Typography variant="body1">
        Please wait while you are redirected
      </Typography>
    </Box>
  );
};

export default withAuth(LinkGhAccount);
