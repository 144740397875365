import axios, { AxiosInstance } from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { SetStateAction } from "react";

export enum AccountType {
  trial = "trial",
  paid = "paid",
}

export enum StatusType {
  success = "success",
  error = "error",
  limit = "limit",
}

export interface User {
  uid: string;
  email: string | null;
  name: string | null;
  created_at: number;
  updated_at: number;
  account_type: AccountType;
  secret: string;
  token_balance: number;
  gh_id: number | null;
  gh_login: string | null;
  gh_avatar_url: string | null;
  gh_access_token_expiration: number | null;
  gh_refresh_token_expiration: number | null;
  gh_access_is_revoked: boolean;
  gh_access_token: string | null;
  gh_refresh_token: string | null;
}

export interface GhPermissions {
  [key: string]: string;
}

export interface Installation {
  installation_id: number;
  created_at: number;
  updated_at: number;
  gh_account_login: string | null;
  gh_account_id: number | null;
  gh_account_type: string | null;
  gh_account_avatar_url: string | null;
  gh_permissions: GhPermissions | null;
  gh_events: string[] | null;
}

export interface RepositoryConfig {
  is_enabled: boolean;
}

export interface RuleItem {
  predefined_rule_id: string;
  arguments: string[];
}

export interface Repository {
  repository_id: number;
  installation_id: number;
  created_at: number;
  updated_at: number;
  billing_account_id: null | string;
  rules: RuleItem[];
  config: RepositoryConfig;
  gh_name: string | null;
  gh_full_name: string | null;
  gh_private: boolean | null;
  gh_owner_login: string | null;
  gh_owner_id: number | null;
  gh_owner_type: string | null;
}

export interface PullReview {
  gh_full_name: string;
  gh_name: string;
  pull_review_id: string;
  repository_id: number;
  created_at: number;
  pull_number: number;
  diff_tokens: number;
  title_tokens: number;
  body_tokens: number;
  rules_tokens: number;
  openai_prompt_tokens: number;
  openai_response_tokens: number;
  status: StatusType;
}

export interface InstallationsResponse {
  installations: Installation[];
  repositories: Repository[];
  pull_reviews: PullReview[];
}

export interface PullReviewResponse {
  pull_reviews: PullReview[];
}

export class ApiClient {
  instance: AxiosInstance;
  clientId = process.env.REACT_APP_GH_CLIENT_ID
  host = process.env.REACT_APP_API_HOST

  constructor() {
    if (!this.clientId) {
      console.error("Missing GitHub Client ID");
    }
    this.instance = axios.create();
    this.instance.interceptors.request.use(async (config) => {
      try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        config.headers.Authorization = `Bearer ${token}`;
      } catch (e) {
        console.error(e);
      }

      return config;
    });
  }

  async getUserInfo(): Promise<User> {
    try {
      const auth = getAuth();
      const uid = auth.currentUser.uid;
      const response = await this.instance.get(`${this.host}/v1/users/${uid}`, {
        headers: {
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getInstallations(): Promise<InstallationsResponse> {
    const response = await this.instance.get(`${this.host}/v1/installations`, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  }

  async createOrGetUser(): Promise<User> {
    const response = await this.instance.post(
      `${this.host}/v1/users`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }

  async exchangeAccessCode(code: string): Promise<User> {
    try {
      const auth = getAuth();
      const uid = auth.currentUser.uid;
      const response = await this.instance.post(
        `${this.host}/v1/users/${uid}/gh_access_code`,
        { code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getPullReviews(
    installationId: number,
    repositoryId: number
  ): Promise<PullReviewResponse> {
    const response = await this.instance.get(
      `${this.host}/v1/installations/${installationId}/repositories/${repositoryId}/pull_reviews`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  }

  async updateRepositoryConfig(
    installationId: number,
    repositoryId: number,
    config: RepositoryConfig
  ): Promise<Repository> {
    const response = await this.instance.patch(
      `${this.host}/v1/installations/${installationId}/repositories/${repositoryId}`,
      { config },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }

  async updateRepositoryRules(
    installationId: number,
    repositoryId: number,
    rules: RuleItem[]
  ): Promise<Repository> {
    const response = await this.instance.patch(
      `${this.host}/v1/installations/${installationId}/repositories/${repositoryId}`,
      { rules },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }

  async updateRepositoryBillingAccount(
    installationId: number,
    repositoryId: number,
    billing_account_id: string
  ): Promise<Repository> {
    const response = await this.instance.patch(
      `${this.host}/v1/installations/${installationId}/repositories/${repositoryId}`,
      { billing_account_id },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }
}

const apiClient = new ApiClient();

export { apiClient };
