import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  User,
  sendPasswordResetEmail,
} from "firebase/auth";

import firebase from "src/firebase";

const auth = firebase.auth;

export interface UserAuthContext {
  user: User | null;
  error: Error | null;
  successMessage: string | null;
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  emailLogIn: (email: string, password: string) => Promise<void>;
  emailSignUp: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  googleLogIn: () => Promise<void>;
  githubLogIn: () => Promise<void>;
  localSendPasswordResetEmail: (email: string) => Promise<void>;
}

const userAuthContext = createContext({} as UserAuthContext);

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function mapFirebaseErrorToFriendlyMessage(error) {
    /**
     * Converts errors from Firebase into a friendly message.
     */
    switch (error.code) {
      case "auth/invalid-email":
        return "The email address is badly formatted.";
      case "auth/user-disabled":
        return "This account has been disabled. Please contact support.";
      case "auth/user-not-found":
        return "No account found with this email. Please check your email or sign up.";
      case "auth/wrong-password":
        return "Wrong password. Please try again or reset your password.";
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
      case "auth/weak-password":
        return "The password is too weak. Please choose a stronger password.";
      default:
        return "An unknown error occurred. Please try again later.";
    }
  }

  async function localSendPasswordResetEmail(email: string) {
    setError(null);
    setSuccessMessage(null);
    try {
      if (!email.trim()) {
        setError(Error("Please enter your email address."));
        return;
      }
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset email sent, please check your inbox.");
    } catch (err) {
      console.log(err);
      const friendlyError = mapFirebaseErrorToFriendlyMessage(err);
      // error must be of type error:
      const error = new Error(friendlyError);
      setError(error);
    }
  }

  async function emailLogIn(email: string, password: string) {
    setError(null);
    setSuccessMessage(null);
    try {
      setEmail(email);
      setPassword(password);
      await signInWithEmailAndPassword(auth, email, password);
      setEmail("");
      setPassword("");
    } catch (err) {
      const friendlyError = mapFirebaseErrorToFriendlyMessage(err);
      // error must be of type error:
      const error = new Error(friendlyError);
      setError(error);
    }
  }

  async function emailSignUp(email: string, password: string) {
    setError(null);
    setSuccessMessage(null);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
      const friendlyError = mapFirebaseErrorToFriendlyMessage(err);
      // error must be of type error:
      const error = new Error(friendlyError);
      setError(error);
    }
  }

  async function googleLogIn() {
    setError(null);
    setSuccessMessage(null);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  }

  async function githubLogIn() {
    setError(null);
    setSuccessMessage(null);
    const provider = new GithubAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  }

  async function logOut() {
    setError(null);
    setSuccessMessage(null);
    try {
      await signOut(auth);
      localStorage.clear();
    } catch (err) {
      console.log(err);
      setError(err);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, [setUser]);

  return (
    <userAuthContext.Provider
      value={{
        user,
        error,
        email,
        password,
        setEmail,
        setPassword,
        successMessage,
        emailLogIn,
        emailSignUp,
        logOut,
        googleLogIn,
        githubLogIn,
        localSendPasswordResetEmail,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
