import { useState, useEffect, useCallback } from "react";
import { apiClient, User, InstallationsResponse, RuleItem } from "src/api";

const useApi = () => {
  // User related state
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [hasGhAccessToken, setHasGhAccessToken] = useState(false);

  // Installations related state
  const [installationsInfo, setInstallationsInfo] =
    useState<InstallationsResponse | null>(null);

  // Shared state
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [fetchedAt, setFetchedAt] = useState<Date | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "null");
    setUserInfo(user);
    const installations = JSON.parse(
      localStorage.getItem("installations") || "null"
    );
    setInstallationsInfo(installations);
  }, []);

  useEffect(() => {
    if (userInfo !== null && userInfo !== undefined && !isLoading) {
      localStorage.setItem("user", JSON.stringify(userInfo));
    }
  }, [isLoading, userInfo]);

  useEffect(() => {
    if (
      installationsInfo !== null &&
      installationsInfo !== undefined &&
      !isLoading
    ) {
      localStorage.setItem("installations", JSON.stringify(installationsInfo));
    }
  }, [installationsInfo, isLoading]);

  const getUser = useCallback(async (refreshing = false) => {
    if (refreshing) {
      setIsRefreshing(true);
    } else {
      setIsLoading(true);
    }
    setError(null);
    try {
      const userInfo = await apiClient.createOrGetUser();
      setUserInfo(userInfo);
      setFetchedAt(new Date());
      const hasToken = userInfo.gh_access_token_expiration !== null;
      const notRevoked = userInfo.gh_access_is_revoked === false;
      const notExpired =
        userInfo.gh_refresh_token_expiration > new Date().getTime();
      setHasGhAccessToken(hasToken && notRevoked && notExpired);
    } catch (error) {
      setError(error);
      console.error("Error fetching user: ", error);
    } finally {
      if (refreshing) {
        setIsRefreshing(false);
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  const setUser = useCallback((user: User) => {
    setUserInfo(user);
    setIsLoading(false);
    setIsRefreshing(false);
    setFetchedAt(new Date());
    setError(null);
    const hasToken = user.gh_access_token_expiration !== null;
    const notRevoked = user.gh_access_is_revoked === false;
    const notExpired = user.gh_refresh_token_expiration > new Date().getTime();
    setHasGhAccessToken(hasToken && notRevoked && notExpired);
  }, []);

  const linkGhAccount = useCallback(
    async (code: string) => {
      setIsLoading(true);
      try {
        const user = await apiClient.exchangeAccessCode(code);
        console.log("exchanged access code and got user: ", user);
        setUser(user);
      } catch (error) {
        setError(error);
        console.error("Error linking GitHub account: ", error);
      } finally {
        setIsLoading(false);
      }
    },
    [setUser]
  );

  const getInstallations = useCallback(
    async (refreshing = false) => {
      console.log("Getting installs");
      if (!hasGhAccessToken) {
        console.log(userInfo);
        console.warn("Can't fetch installations: no access token");
        return;
      }
      if (refreshing) {
        console.log("Setting refreshing");
        setIsRefreshing(true);
      } else {
        console.log("Setting loading");
        setIsLoading(true);
      }
      setError(null);
      try {
        const data = await apiClient.getInstallations();
        setInstallationsInfo(data);
        setFetchedAt(new Date());
      } catch (error) {
        setError(error);
        console.error("Error fetching installations: ", error);
      } finally {
        if (refreshing) {
          setIsRefreshing(false);
        } else {
          setIsLoading(false);
        }
      }
    },
    [hasGhAccessToken, userInfo]
  );

  const setInstallations = useCallback(
    (installations: InstallationsResponse) => {
      setInstallationsInfo(installations);
      setIsLoading(false);
      setIsRefreshing(false);
      setFetchedAt(new Date());
      setError(null);
    },
    []
  );

  const refreshUser = useCallback(() => {
    getUser(true);
  }, [getUser]);

  const refreshInstallations = useCallback(() => {
    getInstallations(true);
  }, [getInstallations]);

  const updateRepoConfig = useCallback(
    async (installationId: number, repoId: number, config: any) => {
      console.log("Updating repo config");
      setIsLoading(true);
      setError(null);
      try {
        await apiClient.updateRepositoryConfig(installationId, repoId, config);
      } catch (error) {
        setError(error);
        console.error("Error updating repo config: ", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const updateRepoRules = useCallback(
    async (installationId: number, repoId: number, rules: RuleItem[]) => {
      console.log("Updating repo rules");
      setIsLoading(true);
      setError(null);
      try {
        await apiClient.updateRepositoryRules(installationId, repoId, rules);
      } catch (error) {
        setError(error);
        console.error("Error updating repo rules: ", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (hasGhAccessToken) {
      getInstallations();
    }
  }, [hasGhAccessToken, getInstallations]);

  return {
    userInfo,
    isLoading,
    isRefreshing,
    fetchedAt,
    error,
    hasGhAccessToken,
    refreshUser,
    setUser,
    installationsInfo,
    refreshInstallations,
    getInstallations,
    setInstallations,
    updateRepoConfig,
    updateRepoRules,
    linkGhAccount,
  };
};

export default useApi;
